import React from 'react';
import {
    BronsonButton,
    BronsonFormSelect,
    BronsonSpinner,
} from '@dh/bronson-react';

import { DealershipType } from '../../utils/interfaces';
import { dealersLabels } from '../../views/Dealers/dealers.labels';

/**
 * View responsible for providing information of the dealers
 */
export const DealersList: React.FC<{
    dealerships: Array<DealershipType>;
    registering: boolean;
    dealer: string;
    actions: {
        setDealer: (dealer: string) => void;
        setUserAttribute: () => void;
    };
}> = ({ dealerships, registering, dealer, actions }) => {
    const hasError = !dealerships.length;
    return (
        <div
            id='dealers-view'
            className='o-component-wrapper u-mt-xsmall@s u-mt-large u-mb-large'
        >
            <div className='o-page-wrap o-page-wrap--xsmall'>
                <h5 className='u-text-brand u-text-center u-mv'>
                    {dealersLabels.dealership}
                </h5>
                <hr className='c-divider' />
                <div className='o-fieldset u-mb'>
                    <div className='o-fieldset__row'>
                        <p>{dealersLabels.associatedDealers}</p>
                        <BronsonFormSelect
                            isDisabled={false}
                            elementId='dealerId'
                            defaultValue={''}
                            onChange={(dealer) => {
                                actions.setDealer(dealer.value);
                            }}
                            label={dealersLabels.dealers}
                            hasError={hasError}
                            errorMessage={dealersLabels.emptyDealers}
                        >
                            <option disabled value=''></option>
                            {dealerships.length &&
                                dealerships.map((dealer) => (
                                    <option
                                        key={dealer.dealerCode}
                                        value={dealer.dealerCode}
                                    >
                                        {`${dealer.dealerCode} - ${dealer.companyName}`}
                                    </option>
                                ))}
                        </BronsonFormSelect>
                    </div>
                </div>

                <div className='o-layout o-layout--equal-height o-layout--right u-mv'>
                    <div className='o-layout__item  u-1/3 u-1/1@xs'>
                        <div className='o-button-container  o-button-container--right'>
                            {registering ? (
                                <BronsonSpinner
                                    config={{
                                        spinnerWrapperClassModifier:
                                            'c-spinner--small',
                                        showSpinner: true,
                                    }}
                                />
                            ) : (
                                <BronsonButton
                                    dataComponent='btn-confirm'
                                    label={dealersLabels.btnNext}
                                    click={() => {
                                        actions.setUserAttribute();
                                    }}
                                    config={{
                                        types: [''],
                                        isDisable: !!hasError || !dealer,
                                        elementClassModifier:
                                            'o-button-container__button',
                                    }}
                                ></BronsonButton>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
